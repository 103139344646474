import React from "react";
import PropTypes from "prop-types";
import WhyPrimoko from "../../containers/about/WhyPrimoko";

const WhyPrimokoPage = ({ classes }) => {
  return (
    <WhyPrimoko
      classes={classes}
    />
  );
};

WhyPrimokoPage.propTypes = {
  classes: PropTypes.object
};

export default WhyPrimokoPage;
