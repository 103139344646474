import React from "react";
import { withPrefix } from "gatsby";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  container: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: theme.spacing.unit * 10
  },
  item: {
    paddingTop: theme.spacing.unit * 10
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black"
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8"
  },
  heading: {
    ...theme.typography.subheading,
    paddingTop: theme.spacing.unit * 3,
    fontSize: '16pt',
    color: "black",
    textTransform: 'uppercase'
  },
  subpara: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    fontSize: '14pt'
  }
});

const OurTeam = ({ classes, location }) => {
  return (
    <Layout location={location}>
      <React.Fragment>
      <div className={classes.root}>
        <div className={classes.subheading}>Why Primoko</div>
        <div className={classes.paragraph}>
          By putting our talents to work, we build products that help you achieve your business objective. <br />And the four pillars that stand atop to define the very soul of Primoko are...
        </div>
        <Grid
          container
          justify="space-between"
          alignitems="center"
          className={classes.container}
          spacing={24}
        >
          <Grid item className={classes.item} sm={12} md={3}>
            <img
              className={classes.image}
              src={withPrefix('why/relationship.png')}
              width="68px"
              height="42px"
            />
            <div>
              <Typography className={classes.heading}>Relationship</Typography>
              <Typography className={classes.paragraph}>One of the key elements to our success is our relationships with clients, the ability to trust and be trusted. Trust is earned by being honest, performing consistently, and delivering great results.</Typography>
            </div>
          </Grid>
          <Grid item className={classes.item} sm={12} md={3}>
            <img
              className={classes.image}
              src={withPrefix('why/transparency.png')}
              width="53px"
              height="53px"
            />
            <div>
              <Typography className={classes.heading}>Transparency</Typography>
              <Typography className={classes.paragraph}>Our clients can depend on us because we hold us accountable for our projects- no excuses, our processes are transparent- no conspiracy theory or private discussions and finally no hidden charges.</Typography>
            </div>
          </Grid>
          <Grid item className={classes.item} sm={12} md={3}>
            <img
              className={classes.image}
              src={withPrefix('why/quality.png')}
              width="53px"
              height="53px"
            />
            <div>
              <Typography className={classes.heading}>Quality</Typography>
              <Typography className={classes.paragraph}>The will to win, the desire to succeed and the urge to reach our full potential can all be boiled down to one word: excellence. Commitment to excellence at every stage from planning to delivery, is the key to our quality.</Typography>
            </div>
          </Grid>
          <Grid item className={classes.item} sm={12} md={3}>
            <img
              className={classes.image}
              src={withPrefix('why/knowledge.png')}
              width="53px"
              height="53px"
            />
            <div>
              <Typography className={classes.heading}>Knowledge</Typography>
              <Typography className={classes.paragraph}>We are constantly researching about the latest technology, working hard on improving our work processes and developing robust solutions for optimal cost : performance ratio.</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
    </Layout>
  );
};

export default withStyles(styles)(OurTeam);
